import Home from "../Home";
import MetaOg from "../MetaOg";

export const paths = [
    {
        path: "/",
        element: <Home props={{context: "main", motion: true}}/>
    },
    {
        path: "product",
        element: <Home props={{context: "main", motion: true, scrollTo: "product"}}/>
    },
    {
        path: "pwdLess",
        element: <Home props={{context: "pwdLess", motion: true}}/>
    },
    {
        path: "mfa",
        element: <Home props={{context: "mfa", motion: true}}/>
    },
    {
        path: "dir",
        element: <Home props={{context: "dir", motion: true}}/>
    },
    {
        path: "vault",
        element: <Home props={{context: "vault", motion: true}}/>
    },
    {
        path: "apps",
        element: <Home props={{context: "apps", motion: true}}/>
    },
    {
        path: "policy",
        element: <Home props={{context: "policy", motion: true}}/>
    },
    {
        path: "compl",
        element: <Home props={{context: "compl", motion: true}}/>
    },
    {
        path: "vuln",
        element: <Home props={{context: "vuln", motion: true}}/>
    },
    {
        path: "patch",
        element: <Home props={{context: "patch", motion: true}}/>
    },
    {
        path: "browser",
        element: <Home props={{context: "browser", motion: true}}/>
    },
    {
        path: "media",
        element: <Home props={{context: "media", motion: true}}/>
    },
    {
        path: "freeTrial",
        element: <Home props={{context: "freeTrial", motion: false}}/>
    },
    {
        path: "privacyPolicy",
        element: <Home props={{context: "privacyPolicy", motion: false}}/>
    },
    {
        path: "aboutUs",
        element: <Home props={{context: "aboutUs", motion: false}}/>
    },
    {
        path: "pricing",
        element: <Home props={{context: "pricing", motion: false}}/>
    },
    {
        path: "freeTrialSuccess",
        element: <Home props={{context: "freeTrialSuccess", motion: false}}/>
    },
    {
        path: "freeTrialInProgress",
        element: <Home props={{context: "freeTrialInProgress", motion: false}}/>
    },
    {
        path: "freeTrialError",
        element: <Home props={{context: "freeTrialError", motion: false}}/>
    },
    {
        path: "metaOg",
        element: <MetaOg props={{context: "metaOg", motion: false}}/>
    },
];
