
import Header from "../summary/Header";
import GetStarted from "../../GetStarted";
import DetailHeroLeft from "./DetailHeroLeft";
import DetailHeroRight from "./DetailHeroRight";
import Enrichers from "./Enrichers";
import DetailLeft from "./DetailLeft";
import DetailRight from "./DetailRight";
import {addBreaks} from "../Style";

const Detail = ({header, heroRight, noOfBreaksAfterHero, about, img, alt, complimentaryFeatures, bodyArr}) => {
    return (
        <>
            {addBreaks(5)}
            <div className="bg-primary-black overflow-hidden">
                <div className="relative mt-14">
                    <Header header={header} toUpper={true}/>
                    {heroRight &&
                        <DetailHeroRight about={about} img={img} alt={alt}/>
                    }
                    {!heroRight &&
                        <DetailHeroLeft about={about} img={img} alt={alt}/>
                    }
                    {addBreaks(noOfBreaksAfterHero)}
                    {bodyArr && bodyArr.map((body, bodyIdx) => (
                        <div key={bodyIdx}>
                            {addBreaks(3)}
                            {bodyIdx % 2 === 0 &&
                                <DetailLeft body={body.body} img={body.img} alt={body.alt}/>
                            }
                            {bodyIdx % 2 !== 0 &&
                                <DetailRight body={body.body} img={body.img} alt={body.alt}/>
                            }
                            {addBreaks(3)}
                        </div>
                    ))}
                    <br />
                    <Enrichers id={header} complimentaryFeatures={complimentaryFeatures} />
                    {addBreaks(3)}
                    <GetStarted />
                    {addBreaks(3)}
                </div>
            </div>
        </>
    );
}

export default Detail;
