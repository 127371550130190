import {
    F_ACCESS_POLICY,
    F_CLOUD_DIRECTORY,
    F_MFA,
    F_PASSWORDLESS,
    F_SSO,
} from "../util/Features";
import Detail from "../util/detail/Detail";

const Passwordless = () => {
    const complimentaryFeatures = [
        F_MFA,
        F_SSO,
        F_ACCESS_POLICY,
        F_CLOUD_DIRECTORY
    ];

    return (
        <div>
            <Detail
                header={F_PASSWORDLESS.title}
                heroRight={false}
                about={F_PASSWORDLESS.detail}
                img={F_PASSWORDLESS.img}
                alt={F_PASSWORDLESS.alt}
                noOfBreaksAfterHero={2}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default Passwordless;
