import styles, {styles1} from "../Style";
import shield from "../../../assets/img/Shield.svg";
const Steps = ({ number, text }) => (
    <div className={`${styles1.flexCenter} flex-row`}>
        <div className={``}>
            <p className={`w-[40px] h-[40px] rounded-full ${styles.flexCenter} `}>
                <img src={shield} alt="icon" className="h-[32px] object-contain"/>
            </p>
        </div>
        <p className="flex-1 ml-[30px] font-normal text-[#B0B0B0] leading-[32.4px] sm:text-[20px] text-[14px]">
            {text}
        </p>
    </div>
);

export default Steps;
