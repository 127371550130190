import { useState } from "react";
import {motion} from "framer-motion";
import logo from "./../assets/img/logo.png"
import close from "./../assets/img/close.svg"
import menu from "./../assets/img/menu.svg"
import {navLinks} from "./util/NavLinks";
import Button from "./Button";
import {navVariant} from "./util/Motion";

const Nav = () => {
    const [active, setActive] = useState("Home");
    const [toggle, setToggle] = useState(false);

    return (
        <nav className="w-full flex py-6 justify-between items-center navbar">
            <a href="/" rel="noreferrer">
            <img src={logo} alt="ssosec" className="w-[140px] h-[32px]" />
            </a>
            <ul className="list-none sm:flex hidden justify-end items-center flex-1">
                {navLinks.map((nav, index) => (
                    <div key={"nav-" + index}>
                        {!nav.multi &&
                            <li
                                key={nav.id}
                                className={`font-poppins font-normal cursor-pointer text-[16px] ${
                                    active === nav.title ? "text-white" : "text-dimWhite"
                                } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
                                onClick={() => setActive(nav.title)}
                            >
                                <a href={`${nav.id}`}>{nav.title}</a>
                            </li>
                        }
                        {nav.multi &&
                            <></>
                        }
                    </div>
                ))}
            </ul>
            <div className="sm:flex hidden ml-10 ">
                <a href="/freeTrial">
                    <Button btnText={"Free Trial"}/>
                </a>

            </div>
            <div className="sm:hidden z-40 flex flex-1 justify-end items-center">
                <img
                    src={toggle ? close : menu}
                    alt="menu"
                    className="w-[28px] h-[28px] object-contain"
                    onClick={() => setToggle(!toggle)}
                />

                <div
                    className={`${
                        !toggle ? "hidden" : "flex"
                    } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
                >
                    <ul className="list-none flex justify-end items-start flex-1 flex-col">
                        {navLinks.map((nav, index) => (
                            <li
                                key={nav.id}
                                className={`font-poppins font-medium cursor-pointer text-[16px] ${
                                    active === nav.title ? "text-white" : "text-dimWhite"
                                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                                onClick={() => setActive(nav.title)}
                            >
                                <a href={`${nav.id}`}>{nav.title}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

const Navbar = ({wMotion}) => {

    return (
        <>
            {wMotion &&
                <motion.nav
                    variants={navVariant}
                    initial="hidden"
                    whileInView="show"
                >
                    <Nav />
                </motion.nav>
            }
            {!wMotion &&
                <Nav />
            }
        </>
    );
};

export default Navbar;
