
import {fadeIn, staggerContainer} from "../Motion";
import {addBreaks, spanEl, styles1} from "../Style";
import {motion} from "framer-motion";

const Enrichers = ({id, complimentaryFeatures}) => {
    return (
        <>
            <br />
        <div className={`flex flex-col rounded-[20px] box-shadow`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{once: false, amount: 0.25}}
                className={`${styles1.innerWidth} font-poppins text-white mx-auto ${styles1.flexCenter} flex-col`}
            >
                <motion.p variants={fadeIn('up', 'tween', 0.2, 1)}>
                    <br />
                    <br />
                    <span className={'text-[20px] font-normal'}>
                        Discover the SSOSEC features that synchronize seamlessly with
                    </span>
                    <span className={'text-[20px] font-medium'}>
                        {spanEl(id, true)}
                    </span>
                </motion.p>
            </motion.div>
            <br />
            <br />
            <div className={`${styles1.flexCenter} flex-col md:flex-row items-stretch h-full`}>
                {complimentaryFeatures && complimentaryFeatures.map((feature, index1) => (
                    <div key={index1} className="bg-black-gradient-2 feature-card flex text-white justify-between
                        flex-col px-10 py-12 rounded-[20px]  max-w-[370px] md:mr-10 sm:mr-5 mr-0 my-5 feedback-card">
                        <div>
                            <motion.div
                                variants={staggerContainer}
                                initial="hidden"
                                whileInView="show"
                                viewport={{once: false, amount: 0.25}}
                                className={`${styles1.innerWidth} mx-auto ${styles1.flexCenter}`}
                            >
                                <motion.div
                                    variants={fadeIn('up', 'tween', 0.2, 1)}
                                    className="mt-[8px] font-normal text-dimWhite"
                                >
                                    <div>{feature.title}</div><br />
                                    <div>{feature.summary}</div>
                                </motion.div>
                            </motion.div>
                        </div>
                        {feature.link &&
                            <div>
                                <div className={'float-right text-[15px] animate-pulse hover:animate-none'}>
                                    <a href={"/" + `${feature.link}`}>
                                        <button className={'p-1 bg-none rounded-[10px] outline-none hover:bg-none'}>
                                                <span className={'text-white'}>
                                                    Explore&#8599;
                                                </span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>
            {addBreaks(4)}
        </div>
        </>
    );

}

export default Enrichers;
