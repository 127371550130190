import React from 'react';
import Typed from 'react-typed';

import cloudNLock from '../assets/vid/cloud-n-lock.mp4'
import Button from "./Button";
import { useNavigate} from "react-router-dom";
import {addBreaks} from "./util/Style";

const Hero = () => {
    let navigate = useNavigate();
    const freeTrail = () => {
        console.log("freeTrail")
        navigate("/freeTrial")
    }
    return (
        <header className="relative flex items-center justify-center h-screen overflow-hidden">
            <div className="relative z-30 text-black">
                {addBreaks(3)}
                <div
                    className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                    <p className='font-bold p-2 text-4xl'>
                        Protect your enterprise from security threats
                    </p>
                    <p className='font-bold p-2'>
                        Secure your Users, Apps & Devices using <span className="text-[#dc3545]">SSOSEC’s Zero Trust architecture</span>
                    </p>
                    <br/>
                    <div className='flex justify-center items-center'>
                        <Typed
                            className='text-gradient md:text-4xl sm:text-3xl text-xl font-bold md:pl-4 pl-2'
                            strings={['PASSWORDLESS', 'SSO + MFA', 'VAULT', 'DEVICE COMPLIANCE', 'VULNERABILITY ASSESSMENT',
                                'PATCH MANAGEMENT', 'MEDIA PROTECTION']}
                            typeSpeed={60}
                            backSpeed={8}
                            loop
                        />
                    </div>
                    <br/>
                    {/*<Button styles={"w-[200px] h-[60px] mx-auto"} btnText={"Learn More"} />*/}
                    <br/>

                    <a href="/freeTrial">
                        <Button styles={"sm:hidden w-[200px] h-[60px] mx-auto"} btnText={"Free Trial"}/>
                    </a>
                </div>
            </div>
            <video autoPlay loop muted className="absolute z-10 w-auto min-w-full min-h-full max-w-none">
                <source src={cloudNLock} type="video/mp4"/>
            </video>
        </header>
    );
};

export default Hero;
