import {styles1} from "../Style";
import {fadeIn, staggerContainer} from "../Motion";
import {motion} from "framer-motion";
const Header = ({header, toUpper, styles}) => {
    return(
        <>
            <section className={`relative z-10`}>
                <motion.div
                    variants={staggerContainer}
                    initial="hidden"
                    whileInView="show"
                    viewport={{once: false, amount: 0.25}}
                    className={`${styles1.innerWidth} ${styles1.heroHeading} mx-auto ${styles1.flexCenter} flex-col`}
                >
                    <motion.p variants={fadeIn('up', 'tween', 0.2, 1)}
                              className={toUpper ? 'uppercase' : '' + styles}
                    >
                        {header}
                    </motion.p>
                </motion.div>
            </section>
        </>
    );
}

export default Header
