import styles, {styles1} from "../Style";
import {motion} from "framer-motion";
import {benefitsVariants, fadeIn, staggerContainer} from "../Motion";

const DetailLeft = ({body, img, alt}) => {

    return (
        <section className={`${styles.paddings} relative z-10`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
            >
                <motion.div
                    variants={benefitsVariants('left')}
                    className={`flex-1 ${styles.flexCenter}`}
                >
                    <img src={img} alt={alt} className="w-[90%] h-[90%] object-contain"/>
                </motion.div>
                <motion.div
                    variants={fadeIn('left', 'tween', 0.2, 1)}
                    className="flex-1 flex flex-row "
                >
                    <div className={`${styles1.flexCenter}`}>
                        <div className={'flex flex-col'}>
                            {body}
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </section>
    );

}

export default DetailLeft;
