import {
    F_BROWSER_MANAGEMENT,
    F_DEVICE_COMPLIANCE,
    F_MEDIA_PROTECTION,
    F_PATCH_MANAGEMENT,
    F_VULNERABILITY_ASSESSMENT,
} from "../util/Features";
import Detail from "../util/detail/Detail";


const DeviceCompliance = () => {
    const complimentaryFeatures = [
        F_PATCH_MANAGEMENT,
        F_VULNERABILITY_ASSESSMENT,
        F_BROWSER_MANAGEMENT,
        F_MEDIA_PROTECTION,
    ];

    return (
        <div>
            <Detail
                header={F_DEVICE_COMPLIANCE.title}
                heroRight={true}
                about={F_DEVICE_COMPLIANCE.detail}
                img={F_DEVICE_COMPLIANCE.img}
                alt={F_DEVICE_COMPLIANCE.alt}
                noOfBreaksAfterHero={10}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default DeviceCompliance;


