import {
    F_ACCESS_POLICY,
    F_CLOUD_DIRECTORY,
    F_MFA,
    F_PASSWORDLESS,
    F_SSO,
} from "../util/Features";
import Detail from "../util/detail/Detail";

const SSO = () => {
    const complimentaryFeatures = [
        F_CLOUD_DIRECTORY,
        F_MFA,
        F_ACCESS_POLICY,
        F_PASSWORDLESS
    ];

    return (
        <div>
            <Detail
                header={F_SSO.title}
                heroRight={true}
                about={F_SSO.detail}
                img={F_SSO.img}
                alt={F_SSO.alt}
                noOfBreaksAfterHero={12}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default SSO;
