import {
    F_ACCESS_POLICY,
    F_BROWSER_MANAGEMENT,
    F_MEDIA_PROTECTION,
    F_PATCH_MANAGEMENT,
    F_VULNERABILITY_ASSESSMENT,
} from "../util/Features";
import Detail from "../util/detail/Detail";

const VulnerabilityAssessment = () => {
    const complimentaryFeatures = [
        F_PATCH_MANAGEMENT,
        F_BROWSER_MANAGEMENT,
        F_ACCESS_POLICY,
        F_MEDIA_PROTECTION
    ];

    return (
        <div>
            <Detail
                header={F_VULNERABILITY_ASSESSMENT.title}
                heroRight={false}
                about={F_VULNERABILITY_ASSESSMENT.detail}
                img={F_VULNERABILITY_ASSESSMENT.img}
                alt={F_VULNERABILITY_ASSESSMENT.alt}
                noOfBreaksAfterHero={10}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default VulnerabilityAssessment;




