import styles, {layout, styles1} from "./util/Style";
import solution360 from "../assets/img/solution-360-dark.svg";
import {fadeIn, slideIn, staggerContainer} from "./util/Motion";
import {motion} from "framer-motion";
import {iamFeatures, uemFeatures} from "./util/Features";

const Product = () => {
    return(
        <>
            <div className={"py-12"}/>
            <div className={"text-white"}>
                <section className={`${styles1.paddings} relative z-10`}>
                    <motion.div variants={staggerContainer} initial="hidden" whileInView="show" viewport={{ once: false, amount: 0.25 }}>
                        <div className={`${styles1.innerWidth} flex lg:flex-row flex-col gap-8 justify-between`}>
                            <div/>
                            <motion.div variants={slideIn('left', 'tween', 0.2, 1)}>
                                <Features title={"Identity and Access Management"} features={iamFeatures} side={"right"}/>
                            </motion.div>
                            <motion.div variants={fadeIn('down', 'spring', 0.6, 1)}>
                                <CenterPic />
                            </motion.div>
                            <motion.div variants={slideIn('right', 'tween', 0.2, 1)}>
                                <Features title={"Unified Endpoint Management"} features={uemFeatures} side={"left"}/>
                            </motion.div>
                            <div/>
                        </div>
                    </motion.div>
                </section>
            </div>
        </>
    );
}

export default Product;

const CenterPic = () => {
    return (
        <>
            <div className={`${styles1.flexCenter} flex flex-col`}>
                <span className={"text-gradient text-[65px]"}>Ctrl</span>
                <p className={`${styles.headerSuffix} text-center`}>
                    Security as a service platform.
                </p>
                <div className={"w-full"}>
                    <img src={solution360} alt="icon" className="max-h-[450px] lg:h-[450px] object-contain mx-auto"/>
                </div>
                <p className={`${styles.headerSuffix} text-center`}>
                    An integrated IAM + UEM platform.
                </p>
            </div>
        </>
    );
}

const Features = ({title, features, side}) => {
    let sideStyle = "font-poppins font-normal text-dimWhite text-[16px] leading-[24px]";
    sideStyle += side === "left" ? " ml-9" : " mr-9 text-right";
    let hStyle = "font-poppins font-semibold text-dimWhite text-[22px] leading-[23.4px]";
    hStyle += side === "left" ? " mb-1 ml-3" : " mb-1 mr-3 text-right";
    return (
        <>
            <div className={""}>
                <H1 title={title}/>
                <div className={`${layout.sectionImgR} flex-col`}>
                    {features && features.map((feature, index1) => (
                        <div className={`flex flex-row p-6 rounded-[20px] feature-card`} key={index1}>
                            {side === "left" &&
                                <Img img={feature.img} />
                            }
                            <div className="flex flex-col">
                                <h4 className={hStyle}>
                                    {feature.title}
                                </h4>
                                {feature.content.map((cont, index2) => (
                                    <div key={index2}>
                                        {cont.link.length > 0 &&
                                            <p className={sideStyle}>
                                                <a href={`${cont.link}`}>{cont.title}</a>
                                            </p>
                                        }
                                        {cont.link.length === 0 &&
                                            <p className={sideStyle}>
                                                {cont.title}
                                            </p>
                                        }
                                    </div>
                                ))}
                            </div>
                            {side === "right" &&
                                <Img img={feature.img} />
                            }
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

const Img = ({img}) => {
    return (
        <>
            <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimWhite `}>
                {img}
            </div>
        </>
    );
}

const H1 = ({title}) => {
    return (
        <>
            <div className={`${styles1.flexCenter} flex flex-col`}>
                <div className={"py-24"}>
                    <p className={"text-[18px]"}>{title}</p>
                </div>
            </div>
        </>
    );
}
