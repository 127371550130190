import metaOg from "./../assets/vid/logo512.png"

const MetaOg = (props) => {

    return (
        <div>
            <img
                src={metaOg}
            />
        </div>
    );

}

export default MetaOg;