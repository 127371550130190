import {spanEl, styles1} from "../Style";
import {fadeIn, staggerContainer} from "../Motion";
import {motion} from "framer-motion";

const About = ({about}) => (
    <>
        <section className={`${styles1.paddings} relative z-10`}>
            <div className="gradient-02 z-0"/>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{once: false, amount: 0.25}}
                className={`${styles1.innerWidth} mx-auto ${styles1.flexCenter} flex-col`}
            >
                <motion.p
                    variants={fadeIn('up', 'tween', 0.2, 1)}
                    className="mt-[8px] font-normal sm:text-[22px] text-[14px] text-dimWhite"
                >
                    {about}
                </motion.p>
            </motion.div>
        </section>
    </>
);

export default About;
