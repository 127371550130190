import styles from "./util/Style";
import {useCallback, useEffect, useRef, useState} from "react";

const YTV = ({ videoId, autoPlay, title }) => {

    const videoURL = `https://www.youtube.com/embed/${videoId}${autoPlay ? "?autoplay=1" : ""}`;
    const iframeRef = useRef(null);
    const defaultHeight = 315;
    const [videoHeight, setVideoHeight] = useState(
        iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
    );

    const handleChangeVideoWidth = useCallback(() => {
        const ratio =
            window.innerWidth > 990
                ? 1.0
                : window.innerWidth > 522
                    ? 1.2
                    : window.innerWidth > 400
                        ? 1.45
                        : 1.85;
        const height = iframeRef.current
            ? iframeRef.current.offsetWidth * 0.5625
            : defaultHeight;
        return setVideoHeight(Math.floor(height * ratio));
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleChangeVideoWidth);
        const ratio =
            window.innerWidth > 990
                ? 1.0
                : window.innerWidth > 522
                    ? 1.2
                    : window.innerWidth > 400
                        ? 1.45
                        : 1.85;
        const height = iframeRef.current
            ? iframeRef.current.offsetWidth * 0.5625
            : defaultHeight;
        setVideoHeight(Math.floor(height * ratio));
        return function cleanup() {
            window.removeEventListener("resize", handleChangeVideoWidth);
        };
    }, [videoHeight, handleChangeVideoWidth]);
    return (
        <section id="ytv"
                 className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} `}>
            <div>
                {/* // TODO : not working
                <iframe
                    ref={iframeRef}
                    title={title}
                    width={"100%"}
                    height={`${videoHeight}px`}
                    src={videoURL}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                */}
                <iframe width="560" height="315" src="https://www.youtube.com/embed/R6UWZ8IsmqU"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                </iframe>
            </div>
        </section>
    );

}

export default YTV;
