import React, {useEffect, useRef} from "react";
import styles, {addBreaks} from "./../components/util/Style";
import {motion} from "framer-motion";
import Navbar from "./../components/Navbar";
import Hero from "./../components/Hero";
import Product from "./../components/Product";
import GetStarted from "./../components/GetStarted";
import Footer from "./../components/Footer";
import {staggerContainer} from "./util/Motion";
import FreeTrial from "./FreeTrial";
import PrivacyPolicy from "./PrivacyPolicy";
import AboutUs from "./AboutUs";
import Pricing from "./Pricing";
import FreeTrialSuccess from "./FreeTrialSuccess";
import FreeTrialInProgress from "./FreeTrialInProgress";
import FreeTrialError from "./FreeTrialError";
import YTV from "./YTV";
import Passwordless from "./_iam/Passwordless";
import MFA from "./_iam/MFA";
import CloudDir from "./_iam/CloudDir";
import Vault from "./_iam/Vault";
import SSO from "./_iam/SSO";
import Policy from "./_iam/Policy";
import DeviceCompliance from "./_uem/DeviceCompliance";
import VulnerabilityAssessment from "./_uem/VulnerabilityAssessment";
import PatchManagement from "./_uem/PatchManagement";
import BrowserManagement from "./_uem/BrowserManagement";
import {THE_FEATURES} from "./util/Features";
import Summary from "./util/summary/Summary";
import MediaProtection from "./_uem/MediaProtection";


const Home = (props) => {

    const context = props && props.props && props.props.context;
    const wMotion = props && props.props && props.props.motion;
    const scrollTo = props && props.props && props.props.scrollTo;

    const productRef = useRef(null);

    const contextRender = (context) => {
        switch(context) {
            case "pwdLess": return <Passwordless />;
            case "mfa": return <MFA />;
            case "dir": return <CloudDir />;
            case "vault": return <Vault />;
            case "apps": return <SSO />;
            case "policy": return <Policy />;
            case "compl": return <DeviceCompliance />;
            case "vuln": return <VulnerabilityAssessment />;
            case "patch": return <PatchManagement />;
            case "browser": return <BrowserManagement />;
            case "media": return <MediaProtection />;
            case "freeTrial": return <FreeTrial />;
            case "freeTrialSuccess": return <FreeTrialSuccess />;
            case "freeTrialInProgress": return <FreeTrialInProgress />;
            case "freeTrialError": return <FreeTrialError />;
            case "privacyPolicy": return <PrivacyPolicy />;
            case "aboutUs": return <AboutUs />;
            case "pricing": return <Pricing />;
        }
    }

    useEffect(() => {
        if(scrollTo === "product") {
            productRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [props.props]);

    return (
        <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="show"
            viewport={{ once: false, amount: 0.25 }}
            className={`${styles.innerWidth} mx-auto flex flex-col`}
        >
            <div className="bg-primary w-full overflow-hidden">
                <div className={`${styles.paddingX} ${styles.flexCenter} bg-black-gradient-2 fixed z-40 w-full`}>
                    <div className={`${styles.boxWidth}`}>
                        <Navbar wMotion={wMotion}/>
                    </div>
                </div>
                {context === "main" &&
                    <div id={"home"} className={`${styles.flexStart} bg-black-gradient`}>
                        <div className={`${styles.boxWidth}`}>
                            <Hero />
                        </div>
                    </div>
                }
                <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter} bg-black-gradient`}>
                    <div className={`${styles.boxWidth}`}>
                        {context === "main" &&
                            <>
                                <div ref={productRef}>
                                    <Product />
                                </div>
                                {THE_FEATURES && THE_FEATURES.map((feature, index) => (
                                    <div key={index}>
                                        {addBreaks(3)}
                                        <Summary
                                            header={feature.title}
                                            jsx={feature.summary}
                                            features={feature.benefits}
                                            img={feature.img}
                                            imgAlt={feature.alt}
                                            link={feature.link}
                                            index={index}
                                        />
                                        {addBreaks(3)}
                                    </div>
                                ))}
                                <GetStarted />
                                <YTV videoId={"R6UWZ8IsmqU"} title={"SSOSEC"} />
                            </>
                        }
                        {contextRender(context)}
                        <Footer wMotion={wMotion}/>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default Home;
