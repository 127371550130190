import {motion} from "framer-motion";
import styles from "../Style";
import {benefitsVariants, fadeIn, staggerContainer} from "../Motion";
import Steps from "./Steps";

const Left = ({features, img, imgAlt}) => {
    return (
        <section className={`${styles.paddings} relative z-10`}>
            <motion.div
                variants={staggerContainer}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.25 }}
                className={`${styles.innerWidth} mx-auto flex lg:flex-row flex-col gap-8`}
            >
                <motion.div
                    variants={benefitsVariants('left')}
                    className={`flex-1 ${styles.flexCenter}`}
                >
                    <img src={img} alt={imgAlt} className="w-[90%] max-h-80 object-contain"
                    />
                </motion.div>
                <motion.div
                    variants={fadeIn('left', 'tween', 0.2, 1)}
                    className="flex-[0.75] flex justify-start flex-col"
                >
                    <div className="mt-[31px] flex flex-col max-w-[370px] gap-[24px]">
                        {features.map((feature, index) => (
                            <div key={index}>
                                <Steps
                                    key={feature}
                                    number={`${index < 10 ? '0' : ''} ${index + 1}`}
                                    text={feature}
                                />
                            </div>
                        ))}
                    </div>
                </motion.div>
            </motion.div>
        </section>
    );

}

export default Left;
