import {
    F_ACCESS_POLICY,
    F_BROWSER_MANAGEMENT,
    F_DEVICE_COMPLIANCE,
    F_PATCH_MANAGEMENT,
    F_VULNERABILITY_ASSESSMENT

} from "../util/Features";
import Detail from "../util/detail/Detail";

const PatchManagement = () => {
    const complimentaryFeatures = [
        F_VULNERABILITY_ASSESSMENT,
        F_DEVICE_COMPLIANCE,
        F_BROWSER_MANAGEMENT,
        F_ACCESS_POLICY,
    ];

    return (
        <div>
            <Detail
                header={F_PATCH_MANAGEMENT.title}
                heroRight={true}
                about={F_PATCH_MANAGEMENT.detail}
                img={F_PATCH_MANAGEMENT.img}
                alt={F_PATCH_MANAGEMENT.alt}
                noOfBreaksAfterHero={14}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default PatchManagement;



