
import users from "../../assets/img/users.svg";
import apps from "../../assets/img/cloud-app.svg";
import devices from "../../assets/img/devices.svg";
import data from "../../assets/img/data.svg";
import cloudDir from "../../assets/img/clouddir.png"
import { FaUserAlt } from "react-icons/fa";
import mfa from "../../assets/img/mfa.png"
import pwdL from "../../assets/img/pwdless.png"
import policy from "../../assets/img/policy.png";
import sso from "../../assets/img/sso.png"
import vault from "../../assets/img/vault.png"
import deviceCompliance from "../../assets/img/devicecompliance.png"
import vulnerabilityAssessment from "../../assets/img/vulnerabilityassessment.png"
import patchManagement from "../../assets/img/patchmanagement.png"
import browserManagement from "../../assets/img/browsermanagement.png"
import mediaProtection from "../../assets/img/mediaprotection.png"
import {spanEl} from "./Style";

export const links = {
    CLOUD_DIR: "dir",
    PASSWORDLESS: "pwdLess",
    MFA: "mfa",
    VAULT: "vault",
    SSO: "apps",
    ACCESS_POLICY: "policy",
    DEVICE_COMPLIANCE: "compl",
    VULNERABILITY_ASSESSMENT: "vuln",
    PATCH_MANAGEMENT: "patch",
    BROWSER_MANAGEMENT: "browser",
    MEDIA_PROTECTION: "media",
};

export const iamFeatures = [
    {
        id: "iam-1",
        title: "USERS",
        img: <img src={users} alt="icon" className="h-[32px] object-contain"/>,
        content: [
            {title: "Cloud Directory", link: links.CLOUD_DIR},
            {title: "PASSWORDLESS", link: links.PASSWORDLESS},
            {title: "MFA", link: links.MFA},
            {title: "Secure VAULT", link: links.VAULT},
        ]
    },
    {
        id: "iam-2",
        title: "APPS",
        img: <img src={apps} alt="icon" className="h-[50px] object-contain"/>,
        content: [
            {title: "SSO - SAML", link: links.SSO},
            {title: "SSO - OIDC", link: links.SSO},
            {title: "Access Policy", link: links.ACCESS_POLICY},
        ]
    },
];

export const uemFeatures = [
    {
        id: "uem-1",
        title: "DEVICES",
        img: <img src={devices} alt="icon" className="h-[26px] object-contain"/>,
        content: [
            {title: "Device Compliance", link: links.DEVICE_COMPLIANCE},
            {title: "Vulnerability Assessment", link: links.VULNERABILITY_ASSESSMENT},
            {title: "Patch Management", link: links.PATCH_MANAGEMENT},
            {title: "Browser Management", link: links.BROWSER_MANAGEMENT},
        ]
    },
    {
        id: "uem-2",
        title: "DATA",
        img: <img src={data} alt="icon" className="h-[28px] object-contain"/>,
        content: [
            {title: "Media Protection", link: links.MEDIA_PROTECTION},
            {title: "Anti-Screen Capture", link: ""},
            {title: "Anti-Key Logger", link: ""},
        ]
    },
];

export const F_CLOUD_DIRECTORY = {
    title: "Directory Services",
    summary: <>
        SSOSEC Ctrl has an {spanEl("inbuilt Directory Service", true)}. Easily Manage
        Users, Groups, Devices and their attributes.
    </>,
    detail: <>
        SSOSEC Ctrl comes with a {spanEl("High Performance LDAPv3 compliant")} inbuilt directory which simplifies deployment
        and reduces complexity by making it easy to manage user identities and access controls.
        Seamlessly integrates with all cloud and on-prem use cases. SSOSEC Ctrl also integrates with third party
        directories like Microsoft AD, OpenLDAP etc.
    </>,
    benefits: [
        <>Centralized Identity and Access Management</>,
        <>Cross Platform</>,
        <>Detailed Reporting and Lower TCO</>,
    ],
    link: links.CLOUD_DIR,
    img: cloudDir,
    alt: "cloudDir",
    icon: null,
}

export const F_PASSWORDLESS = {
    title: "PASSWORDLESS",
    summary: <>
        SSOSEC Ctrl's passwordless authentication is an implementation of the {spanEl("FIDO2")} standard.
        It is based on public-key cryptography using asymmetric encryption, where the private key is stored
        locally on the user's device, and the public key is stored on the server.
    </>,
    detail: <>
        SSOSEC Ctrl implementation of the {spanEl("FIDO2 (Fast Identity Online 2)")} standard. It is based on public-key
        cryptography, which provides stronger security compared to passwords. FIDO2 uses asymmetric encryption,
        where the private key is stored locally on the user's device, and the public key is stored on the server.
        This makes it difficult for attackers to steal or intercept the user's credentials.
        FIDO2 uses a unique key pair for every website, which means that users' credentials are not shared between websites.
        This protects users' privacy and reduces the risk of credential reuse attacks.
        The best part is, FIDO2 eliminates the need for users to remember and enter passwords,
        making it more convenient for users. FIDO2 authentication can be performed using biometric factors
        such as fingerprint or facial recognition, or by using hardware tokens.
    </>,
    benefits: [
        <>Eliminates the need for users to remember and enter passwords</>,
        <>Fingerprint or Facial Recognition or Passkey</>,
        <>Unique key pair for every website</>,
        <>Enhanced Security</>,
    ],
    link: links.PASSWORDLESS,
    img: pwdL,
    alt: "pwdL",
    icon: null,
}

export const F_MFA = {
    title: "Multi Factor Authentication",
    summary: <>
        Leveraging, {spanEl("ZERO TRUST")} where authentication and authorization is continuous and
        applies least privilege access. {spanEl("MFA", true)}, adds an extra layer of protection by verifying identities
        through multiple factors such as biometrics, SMS codes, or hardware tokens.
    </>,
    detail: <>
        With a {spanEl("flexible and customisable Authentication framework")}, MFA is inbuilt into the SSOSEC Ctrl Platform.
        MFA provides an additional layer of security beyond username and password authentication.
        By requiring users to provide a second or third factor for authentication, such as a one-time passcode (OTP),
        fingerprint or other factors, MFA significantly reduces the probability for attackers to gain access to
        sensitive information or systems.
    </>,
    benefits: [
        <>Strong Security and Increased accountability</>,
        <>Flexible and Customisable</>,
        <>Compliance</>,
    ],
    link: links.MFA,
    img: mfa,
    alt: "mfa",
    icon: null,
}

export const F_VAULT = {
    title: "VAULT",
    summary: <>
        SSOSEC Ctrl's {spanEl("VAULT")} is a centralized storage of sensitive data with
        identity based access, a must have for a comprehensive IAM solution.
    </>,
    detail: <>
        An {spanEl("integrated VAULT")} that provides encryption and access controls for storing, managing and
        sharing sensitive data such as passwords, API keys, SSH acccess credentials, DB credentials and certificates.
        The rich audit trail ensures addressing all PAM use cases. Legacy applications that cannot be integrated
        into the SSO framework can also be easily protected with SSOSEC Ctrl's MFA layer.
    </>,
    benefits: [
        <>Encryption at Rest</>,
        <>Identity based Secret Access</>,
        <>Addresses all PAM use cases</>,
    ],
    link: links.VAULT,
    img: vault,
    alt: "vault",
    icon: null,
}

export const F_SSO = {
    title: "Single Sign-On",
    summary: <>
        SSOSEC Ctrl simplifies the entire {spanEl("SSO administration")} and end user experience for
        all cloud and internal applications with no compromise on security.
    </>,
    detail: <>
        {spanEl("SSO protected with MFA/passwordless")} reduces the risk of credential theft and unauthorized access,
        as users only need to remember one set of strong credentials. SSO simplifies the login process and
        reduces the number of times users need to enter their credentials, improving the user experience,
        eliminating 'password fatigue', and boosting productivity. SSO reduces costs significantly as password
        related support issues are almost eliminated. SSOSEC Ctrl also provides a centralized audit trail
        for all these application accesses.
    </>,
    benefits: [
        <>Standards Driven, {spanEl("SAML2")} and {spanEl("OIDC/OAUTH2")}</>,
        <>Protect infinite Cloud and Internal Applications</>,
        <>Productivity booster, Reduced Support Costs and Compliance</>,
    ],
    link: links.SSO,
    img: sso,
    alt: "sso",
    icon: null,
}

export const F_ACCESS_POLICY = {
    title: "Access Policy",
    summary: <>
        SSOSEC Ctrl {spanEl("simplifies the policy language")} and enables the administrator to define
        highly customisable and extensible policies, which can either be {spanEl("ABAC")} and/or {spanEl("RBAC", true)}.
    </>,
    detail: <>
        SSOSEC Ctrl has a rich set of access policies, including
        {spanEl("RBAC (Role-Based Access Control) and ABAC (Attribute-Based Access Control)")},
        that help organizations manage access to their resources and ensure that only authorized users can access them.
        These policies are highly customizable and can be applied to both on- premises and cloud-based applications,
        making it easier for organizations to maintain security and compliance across their entire IT environment.
    </>,
    benefits: [
        <>Standards Driven, {spanEl("XACML")}</>,
        <>Protect infinite Cloud and Internal Applications</>,
        <>Robust access controls and policy enforcement</>,
    ],
    link: links.ACCESS_POLICY,
    img: policy,
    alt: "policy",
    icon: null,
}

export const F_DEVICE_COMPLIANCE = {
    title: "Device Compliance",
    summary: <>
        SSOSEC Ctrl ensures {spanEl("robust detection and remediation")} of a wide range of 3rd party and system applications.
    </>,
    detail: <>
        Device compliance ensures that all devices accessing the organization's resources {spanEl("meet the security requirements")},
        such as having the latest operating system updates, antivirus software installed, firewall enabled, disk encrypted and so on.
        This helps prevent security breaches and data theft. SSOSEC Ctrl ensures robust detection and remediation of a
        wide range of 3rd party and system applications helping the devices maintain an enhanced and compliant security posture.
    </>,
    benefits: [
        <>Improved Security Posture</>,
        <>Enhanced operational efficiency</>,
        <>Enhanced Productivity</>,
    ],
    link: links.DEVICE_COMPLIANCE,
    img: deviceCompliance,
    alt: "deviceCompliance",
    icon: null,
}

export const F_VULNERABILITY_ASSESSMENT = {
    title: "Vulnerability Assessment",
    summary: <>
        SSOSEC Ctrl ensures detection and protection from {spanEl("Zero Day Vulnerabilities")} in more than
        700 3rd party applications. The list keeps growing every week.
    </>,
    detail: <>
        By identifying and addressing vulnerabilities, organizations can improve their overall security posture,
        making it more difficult for attackers to breach their systems. SSOSEC Ctrl ensures
        {spanEl("detection and protection from Zero Day Vulnerabilities")}
        across platforms and in more than 700 3rd party applications.
        The list is prioritized by the level of risk. This can help organizations prioritize which areas to focus
        on first when implementing security measures.
    </>,
    benefits: [
        <>Proactive vulnerability detection and mitigation</>,
        <>Improved Security Posture</>,
        <>Intuitive Classification</>,
    ],
    link: links.VULNERABILITY_ASSESSMENT,
    img: vulnerabilityAssessment,
    alt: "vulnerabilityAssessment",
    icon: null,
}

export const F_PATCH_MANAGEMENT = {
    title: "Patch Management",
    summary: <>
        SSOSEC Ctrl along with SSOSEC Trust can {spanEl("automatically patch")} more than 150 applications.
        The list keeps growing every week.
    </>,
    detail: <>
        By identifying and addressing vulnerabilities, organizations can improve their overall security posture,
        making it more difficult for attackers to breach their systems. SSOSEC Ctrl ensures
        {spanEl("detection and protection from Zero Day Vulnerabilities")}
        across platforms and in more than 700 3rd party applications.
        The list is prioritized by the level of risk. This can help organizations prioritize which areas to focus
        on first when implementing security measures.
    </>,
    benefits: [
        <>Simplified software patching and update deployment</>,
        <>Improved Security Posture</>,
        <>Reduced Support Costs</>,
    ],
    link: links.PATCH_MANAGEMENT,
    img: patchManagement,
    alt: "patchManagement",
    icon: null,
}

export const F_BROWSER_MANAGEMENT = {
    title: "Browser Management",
    summary: <>
        SSOSEC Ctrl comes {spanEl("integrated")} with Browser Management policies.
    </>,
    detail: <>
        A significant portion of enterprise work today happens on web browsers.
        SSOSEC Ctrl comes with an integrated
        {spanEl("Browser management that offers a wide range of customizable policies")},
        like allowed extensions, default home page, restricted web pages etc, that can be tailored to meet
        an organization's specific needs. Policies can be set for different user groups, devices, and locations,
        which helps ensure that each user is accessing only the necessary resources.
    </>,
    benefits: [
        <>Secure and controlled web browsing experience</>,
        <>Harden Browser Settings</>,
        <>Detailed Reporting and Compliance</>,
    ],
    link: links.BROWSER_MANAGEMENT,
    img: browserManagement,
    alt: "browserManagement",
    icon: null,
}

export const F_MEDIA_PROTECTION = {
    title: "MEDIA Protection",
    summary: <>
        SSOSEC Ctrl along with SSOSEC Trust effectively establishes a {spanEl("robust Media Protection policy")}.
    </>,
    detail: <>
        Removable media protection policies prevents copy and transfer of sensitive data outside of the
        organization without permission or authorization. This
        {spanEl("prevents data loss, data breaches, or theft of intellectual property")}.
        It also prevents the introduction of malicious software into the
        organizationʼs endpoints which spread laterally and cause serious reputation damage.
        A significant portion of enterprise work today happens on web browsers.
    </>,
    benefits: [
        <>Prevent Data Leakage</>,
        <>Protect Against Malware</>,
        <>Detailed Reporting and Compliance</>,
    ],
    link: links.MEDIA_PROTECTION,
    img: mediaProtection,
    alt: "mediaProtection",
    icon: null,
}

export const F_ANTI_SCREEN_CAPTURE = {
    title: "Anti-Screen Capture",
    summary: <>
        SSOSEC Ctrl's Anti-Screen capture policies{spanEl("prevent unauthorized capture of screen")}
        preventing leak of sensitive or confidential business information.
    </>,
    detail: <>
        SSOSEC Ctrl's Anti-Screen capture policies{spanEl("prevent unauthorized capture of screen")}
        preventing leak of sensitive or confidential business information, such as login credentials, credit card data ,
        medical records, corporate data and personal data.
    </>,
    benefits: [
        <>Prevent Data Leakage</>,
        <>Protect Against Malware</>,
        <>Detailed Reporting and Compliance</>,
    ],
    link: null,
    img: null,
    alt: null,
    icon: null,
}

export const F_ANTI_SCREEN_LOGGER = {
    title: "Anti-Screen Logger",
    summary: <>
        The Anti-Keylogger{spanEl("prevents malware and keyloggers from accessing")} keyboard communications
        by intercepting and encrypting keystrokes.
    </>,
    detail: <>
        Keylogging is the act of capturing every keystroke made on a computer. Keylogging can capture sensitive or private information,
        such as login credentials, credit card numbers, and personal data, without the user's knowledge or consent.
        This can lead to privacy breaches and data theft. The Anti-Keylogger
        {spanEl("prevents malware and keyloggers from accessing")} keyboard communications by intercepting and encrypting keystrokes.
    </>,
    benefits: [
        <>Prevent Data Leakage</>,
        <>Protect Against Malware</>,
        <>Detailed Reporting and Compliance</>,
    ],
    link: null,
    img: null,
    alt: null,
    icon: null,
}

export const THE_FEATURES = [
    F_CLOUD_DIRECTORY,
    F_PASSWORDLESS,
    F_MFA,
    F_VAULT,
    F_SSO,
    F_ACCESS_POLICY,
    F_DEVICE_COMPLIANCE,
    F_VULNERABILITY_ASSESSMENT,
    F_PATCH_MANAGEMENT,
    F_BROWSER_MANAGEMENT,
    F_MEDIA_PROTECTION,
];

