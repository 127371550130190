import {
    F_BROWSER_MANAGEMENT,
    F_DEVICE_COMPLIANCE,
    F_PATCH_MANAGEMENT,
    F_VAULT,
    F_VULNERABILITY_ASSESSMENT,
} from "../util/Features";
import Detail from "../util/detail/Detail";


const BrowserManagement = () => {
    const complimentaryFeatures = [
        F_PATCH_MANAGEMENT,
        F_VULNERABILITY_ASSESSMENT,
        F_DEVICE_COMPLIANCE,
        F_VAULT,
    ];

    return (
        <div>
            <Detail
                header={F_BROWSER_MANAGEMENT.title}
                heroRight={false}
                about={F_BROWSER_MANAGEMENT.detail}
                img={F_BROWSER_MANAGEMENT.img}
                alt={F_BROWSER_MANAGEMENT.alt}
                noOfBreaksAfterHero={10}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default BrowserManagement;

