
/*

//className='bg-gradient-to-r from-pink-500 via-stone-50 to-yellow-500 text-transparent bg-clip-text'
//className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% text-transparent bg-clip-text"
//className="bg-gradient-to-r from-green-400 to-blue-500 text-transparent bg-clip-text"
 */

import ReactDOMServer from "react-dom/server";

const styles = {
    boxWidth: "xl:max-w-[1280px] w-full",

    heading2: "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    heading3: "font-poppins font-semibold xs:text-[25px] text-[25px] text-white w-full",
    heading4: "font-poppins font-semibold xs:text-[20px] text-[20px] text-white w-full",
    paragraph: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px] px-5",
    headerSuffix: "font-poppins font-normal text-dimWhite xs:text-[16px] text-[16px] w-full",

    label: "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]",
    input: " border text-sm rounded-lg w-full p-2.5 " +
        "bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-gray-500 focus:border-gray-500",

    flexCenter: "flex justify-center items-center",
    flexStart: "flex justify-center items-start",
    flexEnd: "flex justify-center items-end",

    paddingX: "sm:px-16 px-6",
    paddingY: "sm:py-16 py-14",
    paddingY2: "sm:py-16 py-6",
    padding: "sm:px-16 px-6 sm:py-12 py-4",

    marginX: "sm:mx-16 mx-6",
    marginY: "sm:my-16 my-6",
    textInfo: "font-poppins font-semibold xs:text-[30px] text-[30px]  text-center color-[#3b99e0]",
    innerWidth: '2xl:max-w-[1280px] w-full',
    aboutUsRow2:" text-center   text-[18px]"

};

export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY}`,
    section2: `flex md:flex-row flex-col ${styles.paddingY2}`,
    section1: `flex md:flex-row flex-col p-0`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY2}`,

    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex  md:ml-10 ml-0 md:mt-0 mt-10 relative py-6`,
    sectionImgL: ``,
    sectionImgR: ``,

    sectionInfo: `flex-1 ${styles.flexStart} flex-col py-6`,
    sectionInfo2: `flex-1 ${styles.flexStart} flex-col`,
    sectionInfo1: `flex-1 ${styles.flexCenter} py-6`,
};

export default styles;

export const spanEl = (text, last) => {
    return <>
        {' '}
        <span className="font-medium bg-gradient-to-r from-red-500 to-yellow-500 text-transparent bg-clip-text">
            {text}
        </span>
        {!last &&
            <>
                {' '}
            </>
        }
    </>;
}

export const styles1 = {
    innerWidth: 'font-poppins font-semibold 2xl:max-w-[1280px] w-full',
    interWidth: 'font-poppins font-semibold lg:w-[80%] w-[100%]',
    paddings: 'sm:p-8 xs:p-4 px-3 py-6',
    yPaddings: 'sm:py-16 xs:py-8 py-12',
    yPaddings1: 'py-2',
    xPaddings: 'sm:px-16 px-6',
    topPaddings: 'sm:pt-16 xs:pt-8 pt-12',
    bottomPaddings: 'sm:pb-16 xs:pb-8 pb-12',
    flexCenter: 'font-poppins font-semibold flex justify-center items-center',
    flexStart: 'font-poppins font-semibold flex justify-start items-start',
    flexEnd: 'font-poppins font-semibold flex justify-end',
    navPadding: 'pt-[98px]',
    heroHeading: 'font-poppins font-semibold lg:text-[60px] md:text-[40px] sm:text-[30px] text-[30px] lg:leading-[114.4px] ' +
        'md:leading-[114.4px] sm:leading-[37.4px] leading-[32.4px] text-white',
}

export const addBreaks = (num) => {
    let buffer = '';
    if (typeof num === "number" && isFinite(num) && num >= 0) {
        Array.from(Array(num), (e, i) => {
            buffer += '<br/>';
        });
    }
    return <div dangerouslySetInnerHTML={{__html: buffer}}/>;
}
