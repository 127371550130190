import {
    F_ACCESS_POLICY, F_ANTI_SCREEN_CAPTURE, F_ANTI_SCREEN_LOGGER,
    F_MEDIA_PROTECTION,
    F_PATCH_MANAGEMENT,
} from "../util/Features";
import Detail from "../util/detail/Detail";

const MediaProtection = () => {
    const complimentaryFeatures = [
        F_ACCESS_POLICY,
        F_PATCH_MANAGEMENT,
        F_ANTI_SCREEN_CAPTURE,
        F_ANTI_SCREEN_LOGGER
    ];

    return (
        <div>
            <Detail
                header={F_MEDIA_PROTECTION.title}
                heroRight={true}
                about={F_MEDIA_PROTECTION.detail}
                img={F_MEDIA_PROTECTION.img}
                alt={F_MEDIA_PROTECTION.alt}
                noOfBreaksAfterHero={14}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default MediaProtection;



