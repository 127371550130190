import {
    F_ACCESS_POLICY,
    F_MFA,
    F_PASSWORDLESS,
    F_SSO,
    F_VAULT
} from "../util/Features";
import Detail from "../util/detail/Detail";


const Vault = () => {
    const complimentaryFeatures = [
        F_ACCESS_POLICY,
        F_PASSWORDLESS,
        F_MFA,
        F_SSO,
    ];

    return (
        <div>
            <Detail
                header={F_VAULT.title}
                heroRight={false}
                about={F_VAULT.detail}
                img={F_VAULT.img}
                alt={F_VAULT.alt}
                noOfBreaksAfterHero={2}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default Vault;
