import {
    F_ACCESS_POLICY,
    F_CLOUD_DIRECTORY,
    F_MFA,
    F_PASSWORDLESS,
    F_SSO,
} from "../util/Features";
import Detail from "../util/detail/Detail";


const Policy = () => {
    const complimentaryFeatures = [
        F_SSO,
        F_PASSWORDLESS,
        F_MFA,
        F_CLOUD_DIRECTORY,
    ];

    return (
        <div>
            <Detail
                header={F_ACCESS_POLICY.title}
                heroRight={false}
                about={F_ACCESS_POLICY.detail}
                img={F_ACCESS_POLICY.img}
                alt={F_ACCESS_POLICY.alt}
                noOfBreaksAfterHero={7}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default Policy;
