import Detail from "../util/detail/Detail";
import {
    F_ACCESS_POLICY,
    F_CLOUD_DIRECTORY,
    F_MFA,
    F_PASSWORDLESS,
    F_SSO,
} from "../util/Features";

const CloudDir = () => {

    const complimentaryFeatures = [
        F_PASSWORDLESS,
        F_MFA,
        F_SSO,
        F_ACCESS_POLICY
    ];

    const bodyArr = [
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Dynamic Cloud-Based Open Directory Platform, offering unparalleled versatility in resource management.
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Departing from the constraints of conventional rigid directories,
                        SSOSEC's embraces open protocols like SAML, OAUTH/OIDC, LDAP, RADIUS, and SCIM.
                        This strategic approach facilitates the centralized administration and effortless
                        integration of diverse resources, elevating operational efficiency.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Comprehensive Centralized Identity Solutions through its native API-based integrations
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Harness the power of SSOSEC's comprehensive centralized identity solutions through its native
                        API-based integrations. These integrations encompass a diverse range of identity sources, 
                        such as Google Workspace, Microsoft 365, and HRIS systems like BambooHR and Personio. 
                        Whether provisioning new user accounts or overseeing existing ones, SSOSEC offers seamless
                        management, consolidating identities into a central repository.

                        Furthermore, SSOSEC's capabilities extend to your Okta or AD cloud directory,
                        enabling efficient management of employee service identities intricately linked to these platforms.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Experience effortless one-click mapping for identity and resource management within SSOSEC.
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Whether you're creating users within the platform or sourcing them from external repositories,
                        you can swiftly provision varying levels of access to your entire spectrum of IT resources
                        by intuitively organizing users into user groups.

                        Additionally, fortify your managed identities with a comprehensive array of
                        security configurations. These include the implementation of conditional access policies,
                        multi-factor authentication, and robust password management policies,
                        ensuring the utmost security and compliance for your organization.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Quickly onboard employees, partners, and customers
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Digitally validate identity information to ensure trustworthy self-service enrollment and faster onboarding.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Access high-value apps and resources
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Quickly verify an individual’s credentials and status to grant least-privilege access with confidence.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Provide self-service account recovery
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Replace support calls and security questions with a streamlined self-service process to verify identities.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
        {
            body : <>
                <div className={'text-white'}>
                    <div className={'text-[30px]'}>
                        Enable credentials to be used anywhere
                    </div>
                    <div className={'text-[15px] text-dimWhite font-normal'}>
                        Ensure that credentials are interoperable and reusable with a solution that supports open standards.
                    </div>
                </div>
            </>,
            img: null,
            alt: null,
        },
    ];

    return (
        <div>
            <Detail
                header={F_CLOUD_DIRECTORY.title}
                heroRight={true}
                about={F_CLOUD_DIRECTORY.detail}
                img={F_CLOUD_DIRECTORY.img}
                alt={F_CLOUD_DIRECTORY.alt}
                noOfBreaksAfterHero={7}
                complimentaryFeatures={complimentaryFeatures}
                //bodyArr={bodyArr}
            />
        </div>
    );
}

export default CloudDir;
