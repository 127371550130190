import Header from "./Header";
import About from "./About";
import styles from "../Style";
import Left from "./Left";
import Right from "./Right";

const Summary = ({header, jsx, features, img, imgAlt, link, index}) => {
    return (
        <>
            <div className="bg-primary-black overflow-hidden">
                <div className="relative mt-14">
                    <Header header={header} toUpper={true}/>
                    <About about={jsx}/>
                    {index % 2 === 0 &&
                        <Left features={features} img={img} imgAlt={imgAlt}/>
                    }
                    {index % 2 !== 0 &&
                        <Right features={features} img={img} imgAlt={imgAlt}/>
                    }
                    <br/>
                    <br/>
                    <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10 animate-pulse hover:animate-none`}>
                        <a href={"/" + `${link}`}>
                            <button className={'p-1 bg-none rounded-[10px] outline-none hover:bg-none'}>
                                <span className={'text-dimWhite'}>
                                    &nbsp;&nbsp;&nbsp;Explore &nbsp;{header}&nbsp;&#8599;&nbsp;&nbsp;&nbsp;
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Summary;
