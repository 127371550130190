import React from "react";
import styles, {layout} from "./util/Style";

const Pricing = () => {
    return (
        <section id="privacyPolicy" className={`${layout.section}`}>
            <div className={`${layout.sectionInfo1} text-white `}>
                <div className="container">
                    <div className="row">
                        <p className={`${styles.heading2}  mt-5`}>
                            Secure Business Is Good Business.
                        </p>
                        <p className={`${styles.paragraph} mt-5`}>
                            For details on pricing, please&nbsp;
                            <a href={"mailto:info@ssosec.com"}>contact us.</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Pricing;
