export const navLinks = [
    {
        id: "useCase",
        title: "Use Case",
        multi: true,
        subItems: {
            id: "pwdLess",
            title: "Passwordless"
        }
    },
    {
        id: "product",
        title: "Product",
    },
    {
        id: "pricing",
        title: "Pricing",
    },
    {
        id: "aboutUs",
        title: "Who We Are",
    }
];