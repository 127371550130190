import {
    F_CLOUD_DIRECTORY,
    F_MFA,
    F_PASSWORDLESS,
    F_SSO,
    F_VAULT,
} from "../util/Features";
import Detail from "../util/detail/Detail";

const MFA = () => {
    const complimentaryFeatures = [
        F_SSO,
        F_PASSWORDLESS,
        F_CLOUD_DIRECTORY,
        F_VAULT,
    ];

    return (
        <div>
            <Detail
                header={F_MFA.title}
                heroRight={true}
                about={F_MFA.detail}
                img={F_MFA.img}
                alt={F_MFA.alt}
                noOfBreaksAfterHero={2}
                complimentaryFeatures={complimentaryFeatures}
            />
        </div>
    );
}

export default MFA;
